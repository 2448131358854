import { Block, Flexbox, useTheme } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type ButtonPlayCircleProps = FlexboxTypes.Props & {
  size?: string
}

const ButtonPlayCircle = (props: ButtonPlayCircleProps) => {
  const { size, ...restProps } = props
  const theme = useTheme()
  return (
    <Flexbox alignItems="center" {...restProps}>
      <Flexbox
        centered
        h={size || '4rem'}
        w={size || '4rem'}
        borderRadius="100%"
        borderStyle="solid"
        borderColor="white"
        borderWidth="0.125rem"
      >
        <Block
          w={0}
          h={0}
          mr="-0.25rem"
          borderWidth="0.75rem 0 0.75rem 1.25rem"
          borderStyle="solid"
          style={{
            borderColor: `transparent transparent transparent ${theme.color.white.hex()}`,
          }}
        />
      </Flexbox>
    </Flexbox>
  )
}

export default ButtonPlayCircle
