import React, { useState } from 'react'

import PageText from '@src/components/PageText'
import PageTitle from '@src/components/PageTitle'
import PageWrap from '@src/components/PageWrap'
import { Block, Button, Flexbox, Grid, Text } from '@stage-ui/core'

import ScreenVideo from '@assets/images/screen_orders.jpg'
import ButtonPlayCircle from '@src/components/ButtonPlayCircle'
import BlockTypes from '@stage-ui/core/layout/Block/types'

import { useFeedback } from '@src/hooks/useFeedback'

import { metrikaGoal } from '@src/utils/metrikaGoal'

import Video from '../Components/Video'

const S05Video = (props: BlockTypes.Props) => {
  const [play, setPlayState] = useState(0)
  const feedback = useFeedback()

  if (play === 2) {
    return (
      <PageWrap id="videoPlayback" my="2rem">
        <Block {...props}>
          <Grid gap={['4rem', '2rem']} templateColumns={['1fr 1fr', '1fr']}>
            <Block>
              <PageTitle label="Что Вас интересует?" title="Можно узнать больше" />
              <Button
                label="Хочу посмотреть систему в живую"
                p={['1.125rem 3.25rem', '1rem']}
                w={['20rem', '100%']}
                h={['5rem', '4rem']}
                mb="m"
                style={{
                  fontWeight: 400,
                  whiteSpace: 'normal',
                  textTransform: 'uppercase',
                }}
                onClick={() => {
                  feedback({
                    title: 'Заявка на живую демонстрацию',
                    sendTitle: 'Отправить заявку',
                  })
                  metrikaGoal('clickVideoFeedback', {
                    title: 'Заявка на живую демонстрацию',
                  })
                }}
              />
              <Button
                label="Хочу посмотреть еще видео"
                p={['1.125rem 3.25rem', '1rem']}
                w={['20rem', '100%']}
                h={['5rem', '4rem']}
                mb="m"
                decoration="outline"
                style={{
                  fontWeight: 400,
                  whiteSpace: 'normal',
                  textTransform: 'uppercase',
                }}
                onClick={() => {
                  feedback({
                    title: 'Хотите посмотреть больше видео о системе?',
                    sendTitle: 'Отправить запрос',
                  })
                  metrikaGoal('clickVideoFeedback', {
                    title: 'Хотите посмотреть больше видео о системе?',
                  })
                }}
              />
            </Block>
            <Flexbox
              w={['40rem', '100%']}
              h={['22rem', '40vw']}
              mt={['4rem', '0']}
              borderRadius="1rem"
              centered
              onClick={() => {
                setPlayState(1)
                metrikaGoal('clickVideoPlayAgain')
              }}
            >
              <Block
                borderRadius="1rem"
                style={{
                  background: `url(${ScreenVideo}) no-repeat`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'right',
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                  filter: 'blur(2px)',
                }}
              />
              <Block
                borderRadius="1rem"
                style={{
                  background:
                    'linear-gradient(83deg, #214B4D 0%, #459889 30%, #82D05E 100%)',
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0.9,
                }}
              />
              <Flexbox centered direction={['row', 'column-reverse']}>
                <Text color="white" weight={600} mr={['m', '0']} mt={['0', 'm']}>
                  Смотреть повторно
                </Text>
                <ButtonPlayCircle size="3rem" />
              </Flexbox>
            </Flexbox>
          </Grid>
        </Block>
      </PageWrap>
    )
  }

  return (
    <>
      <Video
        play={play === 1}
        onClose={() => {
          setPlayState(2)
          if (window) {
            window.location.href = '#videoPlayback'
          }
        }}
      />
      <Block id="videoPlayback" w="100%" backgroundColor="black" {...props}>
        <Block
          style={{
            background: `url(${ScreenVideo}) no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            filter: 'blur(2px)',
          }}
        />
        <Block
          style={{
            background: 'linear-gradient(83deg, #214B4D 0%, #459889 30%, #82D05E 100%)',
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0.9,
          }}
        />
        <PageWrap>
          <Flexbox alignItems="center">
            <Block pb={['7.5rem', '4rem']}>
              <PageTitle
                id="video"
                attributes={{
                  'data-id': 'video',
                }}
                pt={['5.5rem', '2rem']}
                title="Контроль предприятия"
                titleProps={{
                  color: '#fff',
                }}
              />
              <PageText
                textProps={{
                  color: 'white',
                }}
              >
                <b>
                  полный онлайн контроль за <br />
                  производственным процессом, все <br />
                  звенья включены в единую экосистему
                </b>{' '}
                <br />
                (администраторы, операторы, сборщики, <br />
                докупщики, водители)
              </PageText>
            </Block>
            <ButtonPlayCircle
              ml="5.5rem"
              display={['flex', 'none']}
              onClick={() => {
                setPlayState(1)
                metrikaGoal('clickVideoPlay')
              }}
            />
          </Flexbox>
          <Button
            display={['none', 'block']}
            label="Посмотреть видео"
            decoration="outline"
            size="l"
            color="surface"
            shape="round"
            mb="2.5rem"
            onClick={() => {
              setPlayState(1)
              metrikaGoal('clickVideoPlay')
            }}
          />
        </PageWrap>
      </Block>
    </>
  )
}

export default S05Video
